import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/roboto-mono";
import "./utils/globalCss.css";

import AppProvider from "./AppProvider";
import App from "./App";

import NotFound from "./pages/notFound";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <NotFound />
  // <React.StrictMode>
  //   <AppProvider>
  //     <App />
  //   </AppProvider>
  // </React.StrictMode>
);
